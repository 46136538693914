<template>
  <div>
    <div class="send-question">
      <textarea
        placeholder="Type your question here..."
        v-model="question"
      ></textarea>
      <button
        :disabled="question.length === 0 || sendingQuestion"
        @click="sendQuestion()"
      >
        {{ sendingQuestion ? "Sending..." : "Send" }}
      </button>
    </div>
    <h2 class="section-header">
      <img src="@/assets/images/faqs.svg" alt="frequently asked questions" />
      Faqs
    </h2>

    <div v-if="!fetchingFaqs">
      <div class="question-answer" v-for="faq in faqs" :key="faq.id">
        <div class="question">
          <p v-html="faq.question"></p>
          <button @click="toggleQuestion($event)">
            <img
              src="@/assets/images/expand.svg"
              class="expand"
              alt=""
              height="20"
              width="20"
            />
            <img
              src="@/assets/images/collapse.svg"
              class="collapse hide"
              alt=""
              height="20"
              width="20"
            />
          </button>
        </div>
        <div class="answer hide">
          <p v-html="faq.answer"></p>
        </div>
      </div>
    </div>
    <div v-if="fetchingFaqs" class="center-text white">
      <Loader />
    </div>
  </div>
</template>

<script>
import faqs from "../services/faqs";
import Loader from "./Loader.vue";
export default {
  name: "HelpCenter",
  components: {
    Loader
  },
  data() {
    return {
      userID: 0,
      faqs: [],
      fetchingFaqs: false,
      question: "",
      sendingQuestion: false
    };
  },
  methods: {
    toggleQuestion(event) {
      const questionAnswer = event.target.parentNode.parentNode.parentNode;
      const answer = questionAnswer.querySelector(".answer");

      answer.classList.toggle("hide");
      questionAnswer.style.height = answer.offsetHeight + 60 + "px";
      questionAnswer.querySelector(".expand").classList.toggle("hide");
      questionAnswer.querySelector(".collapse").classList.toggle("hide");
    },
    sendQuestion() {
      let body = JSON.stringify({
        userid: this.userID,
        question: this.question
      });

      this.sendingQuestion = true;
      faqs
        .submitFaq(body)
        .then(data => {
          if (data.success) {
            this.$toast.success(data.success);
            this.question = "";
          } else {
            this.$toast.error(data.error);
          }
        })
        .catch((err) => {
          console.log(err);
          // this.$toast.error("please check your network and refresh the page");
        })
        .finally(() => {
          this.sendingQuestion = false;
        });
    }
  },
  created() {
    this.userID = this.$store.getters.getUser.id;

    this.fetchingFaqs = true;
    faqs
      .getFaqs()
      .then(data => {
        if (data.success) {
          this.faqs = data.data.slice();
        } else {
          this.$toast.error(data.error);
        }
      })
      .catch((err) => {
        console.log(err);
        // this.$toast.error("please check your network and refresh the page");
      })
      .finally(() => {
        this.fetchingFaqs = false;
      });
  }
};
</script>

<style scoped>
@import "../styles/section-header.css";
.send-question {
  background: inherit;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 28px;
}
.send-question textarea {
  width: 455px;
  max-width: 90%;
  height: 108px;
  background: #ffffff;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px;
  border: 0;
  font-family: "Gotham-Book";
  font-size: 14px;
}
.send-question textarea:focus {
  outline: 1px solid lightblue;
}
.send-question button {
  background: #5e9cd6;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 0 20px;
  min-width: 75px;
  width: fit-content;
  height: 38px;
  color: #fff;
  font-size: 15px;
  margin: 16px 0;
}
.question-answer {
  background: #fff;
  height: 60px;
  overflow: hidden;
  transition: height 0.5s;
  font-weight: 600;
  margin-top: 26px;
}
.question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 35px;
  font-size: 16px;
}
.question button {
  background: inherit;
  height: 20px;
}

.answer p {
  line-height: 2;
  padding: 20px 35px;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
}

.white {
  background: #fff;
  margin-top: 26px;
}

.hide {
  display: none !important;
}
</style>
